export default{
      /*** Validation Messages ***/
      not_authorized:"You are not authorized to perform that action.",
      minpassword: "Please enter minimum 4 characters.",
      equalpassword: "Passwords are not equal.",
      required: "Please Fill the above field.",
      url:"Please enter valid URL",
      invalid_enddate:'End date should be later than start date',
      no_users_assigned:'No users assigned to this voucher',
      invalid_voucherimage_size:'Voucher image size is invalid',
      invalid_voucheremail_size:'Voucher email size is invalid'
}