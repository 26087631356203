import axios from 'axios';
import {createSlice} from '@reduxjs/toolkit';
import api from '../../config/api';
import messages from '../../config/messages';

const initialState = {
    isAuthorized: true,
    modules: [],
    module_actions: [],
};

export const RoleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        doSessionOutIfNotAuthorized(state, action) {
            if (!action.payload.success && action.payload.message === messages.not_authorized) {
                state.isAuthorized = false;
            }
        },
        changeIsAuthorized: (state, action) => {
            state.isAuthorized = action.payload;
        },
        getModules: (state, action) => {
            state.modules = action.payload;
        },
        getModuleActions: (state, action) => {
            state.module_actions = action.payload;
        },
    },
});

export const {
    getModules,
    getModuleActions,
    changeIsAuthorized,
    doSessionOutIfNotAuthorized
} = RoleSlice.actions;

export const fetchModules = () => async (dispatch) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const { role_id:roleId } = user;
            if (roleId) {
                const response = await axios({
                    method: 'post',
                    url: api.baseurl + api.getmodulesbyrole,
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    data: {role_id: roleId}
                });

                if (typeof response.data.success !== 'undefined' && response.data.success) {
                    dispatch(getModules(response.data.data));
                }

                dispatch(getModules([]));
            }
        }
    } catch (err) {
        //throw new Error(err);
    }
};

export const fetchPermissions = () => async (dispatch) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const { role_id:roleId } = user;
            if (roleId) {
                const response = await axios({
                    method: 'post',
                    url: api.baseurl + api.getrolepermissions,
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    data: {role_id: roleId}
                });

                if (typeof response.data.success !== 'undefined' && response.data.success) {
                    dispatch(getModuleActions(response.data.data.permissions));
                }

                dispatch(getModuleActions([]));
            }
        }
    } catch (err) {
        //throw new Error(err);
    }
};

export default RoleSlice.reducer;
