// import * as UserConstants from 'constants/user';
import React, { useMemo, useState, createContext, useCallback } from 'react';

export const CenterContext = createContext();

export const CenterProvider = ({ children }) => {
    const [center, setCenter] = useState();
  
    const updateCenter = useCallback((newCenter) => {
      setCenter(newCenter);
    }, []);
  
    const values = useMemo(() => {
      return {
        center,
        updateCenter,
      };
    }, [center, updateCenter]);
  
    return <CenterContext.Provider value={values}>{children}</CenterContext.Provider>;
};
